import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
    cpapOrderStatuses,
    determineItemCategoryTags,
    determineOrderCategories,
    estimateStatuses,
    formatDate,
    formatSquarePrice,
    // fulfillmentStatuses,
    homeSleepStudyStatuses,
    orderStatuses,
    orderTypes,
    priorAuthStatuses,
    stringPriceToNumber,
    supplyOrdersStatuses,
} from '../../../utils/constants';

import { useUpdateOrderMutation } from '../../../app/services/admin/orders';
import { useUpdatePatientProfileMutation } from '../../../app/services/auth';
import { useGetInsurancesQuery } from '../../../app/services/admin/insurance';

function GeneralOrderInformation({ order }) {
    //Local state
    const [values, setValues] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    //Redux Mutations
    const [updateOrder] = useUpdateOrderMutation();
    const [updatePatientProfile] = useUpdatePatientProfileMutation();

    //Insurance Query for changing insurance
    const { data: insurances, isFetching: insurancesFetching } = useGetInsurancesQuery(
        order.userId,
    );

    useEffect(() => {
        if (order)
            setValues({
                discountCode: order?.discountCode,
                estimateStatus: order?.estimateStatus,
                fulfillmentStatus: order?.fulfillmentStatus,
                orderStatus: order?.orderStatus,
                orderType: order?.orderType,
                total: order?.total,
                totalDiscount: order?.totalDiscount,
                insuranceId: order?.insurance?.id,
            });
    }, [order]);

    //Track changed values and statuses to determine what to submit and whether reset button is disabled
    const changedValues = {};
    const changedStatuses = {};

    if (values) {
        Object.keys(values)
            .filter((key, i) => values[key] !== order[key])
            .forEach((k) => {
                changedValues[k] = values[k];
            });
    }

    if (statuses) {
        Object.keys(statuses)
            .filter((key, i) => statuses[key] !== order?.user?.patientProfile[key])
            .forEach((k) => {
                changedStatuses[k] = statuses[k];
            });
    }

    const { isCpapOrder, isHSTOrder, isSuppliesOrder, orderItemCategories } =
        determineOrderCategories(order);
    const itemTypes = determineItemCategoryTags(order);

    return insurancesFetching ? (
        <p>Loading..</p>
    ) : (
        <form
            method="post"
            onReset={() =>
                setValues({
                    discountCode: order?.discountCode,
                    estimateStatus: order?.estimateStatus,
                    fulfillmentStatus: order?.fulfillmentStatus,
                    orderStatus: order?.orderStatus,
                    orderType: order?.orderType,
                    total: order?.total,
                    totalDiscount: order?.totalDiscount,
                    insuranceId: order?.insurance?.id,
                })
            }
            onSubmit={async (e) => {
                e.preventDefault();
                setSubmitting(true);

                //Determine if estimate is being rejected on a CPAP order
                //and CPAP order status is not set to NOT_INITIATED
                if (
                    changedValues.estimateStatus === 'ESTIMATE_REJECTED' &&
                    orderItemCategories.includes('CPAP_SUPPLIES') &&
                    order.user.patientProfile.cpapOrderStatus !== 'NOT_INITIATED' &&
                    changedStatuses.cpapOrderStatus !== 'NOT_INITIATED'
                ) {
                    setSubmitting(false);
                    return toast.error(
                        'Cannot reject CPAP estimate without resetting CPAP order status',
                        { autoClose: false },
                    );
                }

                if (Object.keys(changedValues).length > 0) {
                    await updateOrder({
                        orderId: order.id,
                        userId: order.userId,
                        values: {
                            ...changedValues,
                            discountCode: changedValues.discountCode?.toUpperCase(),
                            total: changedValues.total
                                ? stringPriceToNumber(changedValues.total)
                                : values.total,
                            totalDiscount: changedValues.totalDiscount
                                ? stringPriceToNumber(changedValues.totalDiscount)
                                : values.totalDiscount,
                            insuranceId: parseInt(values.insuranceId),
                        },
                    });
                }
                if (Object.keys(changedStatuses).length > 0) {
                    await updatePatientProfile({
                        id: order.user.patientProfile.id,
                        userId: order.user.id,
                        orderId: order.id,
                        patientFields: {
                            ...changedStatuses,
                        },
                    });
                }
                setSubmitting(false);
            }}
            className="grid grid-cols-[180px_1fr_180px_1fr] gap-4 py-4 pl-2">
            <p>Email:</p>
            <div className="flex justify-between">
                <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {order?.user?.email}
                </p>
            </div>

            <p>Insurance:</p>
            <select
                className="w-full rounded-md text-black h-[37px]"
                multiple={false}
                defaultValue={
                    order?.insurance && insurances?.length > 0
                        ? insurances.find((insurance) => insurance?.id === order?.insurance?.id).id
                        : null
                }
                name="insuranceId"
                onChange={(e) => setValues({ ...values, insuranceId: e.target.value })}>
                <option className="text-gray" key="insurance-none" value={null}>
                    None
                </option>
                {insurances?.map((insurance, index) => (
                    <option
                        className="text-white bg-[#2b2e3b]"
                        key={`insurance-${index}`}
                        value={insurance.id}>
                        {insurance?.payer.name + ' (' + insurance?.insuranceStatus + ')'}
                    </option>
                ))}
            </select>

            <p>Order Date:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {formatDate(order?.orderDate)}
            </p>

            <p>Order Id:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">{order.id}</p>

            <p>Last Updated:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {formatDate(order?.updatedAt)}
            </p>

            <p>Items in Order:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {order.orderItems?.length}
            </p>

            <p>Order Type:</p>
            <select
                className="w-full rounded-md text-black h-[37px]"
                multiple={false}
                defaultValue={order.orderType}
                name="orderType"
                onChange={(e) => setValues({ ...values, orderType: e.target.value })}>
                {orderTypes.map((orderType, index) => (
                    <option
                        className="text-white bg-[#2b2e3b]"
                        key={`orderType-${index}`}
                        value={orderType.value}>
                        {orderType.label}
                    </option>
                ))}
            </select>

            <p>Item Types:</p>
            <p>{itemTypes}</p>

            <p>Order Status:</p>
            <select
                className="w-full rounded-md text-black h-[37px]"
                multiple={false}
                defaultValue={order.orderStatus}
                name="orderStatus"
                onChange={(e) => setValues({ ...values, orderStatus: e.target.value })}>
                {orderStatuses.map((orderStatus, index) => (
                    <option
                        className="text-white bg-[#2b2e3b]"
                        key={`orderStatus-${index}`}
                        value={orderStatus.value}>
                        {orderStatus.label}
                    </option>
                ))}
            </select>

            <p>Estimate Status:</p>
            <select
                className="w-full rounded-md text-black h-full"
                multiple={false}
                defaultValue={order?.estimateStatus ? order?.estimateStatus : ''}
                name="estimateStatus"
                onChange={(e) => {
                    setValues({
                        ...values,
                        estimateStatus: e.target.value === '' ? null : e.target.value,
                    });
                }}>
                {estimateStatuses.map((estimateStatus, idx) => (
                    <option key={idx} value={estimateStatus.value ? estimateStatus.value : ''}>
                        {estimateStatus.label}
                    </option>
                ))}
            </select>

            <p>Discount Amount:</p>
            <input
                className="text-black rounded-md pl-4 h-[37px]"
                type="text"
                defaultValue={formatSquarePrice(order.totalDiscount)}
                name="totalDiscount"
                onChange={(e) =>
                    setValues({
                        ...values,
                        totalDiscount: e.target.value,
                    })
                }
            />

            <p>Prior Auth Status:</p>
            <select
                className="w-full rounded-md text-black h-full"
                multiple={false}
                defaultValue={order?.priorAuthStatus ? order?.priorAuthStatus : ''}
                name="priorAuthStatus"
                onChange={(e) => {
                    setValues({
                        ...values,
                        priorAuthStatus: e.target.value === '' ? null : e.target.value,
                    });
                }}>
                {priorAuthStatuses.map((priorAuthStatus, idx) => (
                    <option key={idx} value={priorAuthStatus.value ? priorAuthStatus.value : ''}>
                        {priorAuthStatus.label}
                    </option>
                ))}
            </select>

            <p>Total Price:</p>
            <input
                className="text-black rounded-md pl-4 h-[37px]"
                type="text"
                defaultValue={formatSquarePrice(order.total)}
                name="total"
                values={values?.total}
                onChange={(e) => setValues({ ...values, total: e.target.value })}
            />

            {/* <p>Fulfillment Status:</p>
            <select
                className="w-full rounded-md text-black h-full"
                multiple={false}
                defaultValue={order?.fulfillmentStatus}
                name="fulfullmentStatus"
                onChange={(e) => setValues({ ...values, fulfillmentStatus: e.target.value })}>
                {fulfillmentStatuses.map((opt, idx) => (
                    <option key={idx} value={opt}>
                        {opt}
                    </option>
                ))}
            </select> */}

            <p className="col-start-1">Discount Code:</p>
            <input
                className="text-black rounded-md pl-4 h-[37px]"
                type="text"
                placeholder="None"
                defaultValue={order.discountCode}
                name="discountCode"
                onChange={(e) =>
                    setValues({
                        ...values,
                        discountCode: e.target.value.length > 0 ? e.target.value : null,
                    })
                }
            />

            {isHSTOrder && (
                <>
                    <p>Sleep Study Status:</p>
                    <select
                        className="w-full rounded-md text-black h-full"
                        multiple={false}
                        defaultValue={order?.user?.patientProfile?.homeSleepStudyStatus}
                        name="homeSleepStudyStatus"
                        onChange={(e) =>
                            setStatuses({ ...statuses, homeSleepStudyStatus: e.target.value })
                        }>
                        {homeSleepStudyStatuses.map((opt, idx) => (
                            <option key={idx} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </select>
                </>
            )}

            {isCpapOrder && (
                <>
                    <p>CPAP Order Status:</p>
                    <select
                        className="w-full rounded-md text-black h-full"
                        multiple={false}
                        defaultValue={order?.user?.patientProfile?.cpapOrderStatus}
                        name="cpapOrderStatus"
                        onChange={(e) =>
                            setStatuses({ ...statuses, cpapOrderStatus: e.target.value })
                        }>
                        {cpapOrderStatuses.map((opt, idx) => (
                            <option key={idx} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </select>
                </>
            )}

            {isSuppliesOrder && (
                <>
                    <p>Supply Order Status:</p>
                    <select
                        className="w-full rounded-md text-black h-full"
                        multiple={false}
                        defaultValue={order?.user?.patientProfile?.supplyOrdersStatus}
                        name="supplyOrdersStatus"
                        onChange={(e) =>
                            setStatuses({ ...statuses, supplyOrdersStatus: e.target.value })
                        }>
                        {supplyOrdersStatuses.map((opt, idx) => (
                            <option key={idx} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </select>
                </>
            )}

            <button
                disabled={
                    (Object.keys(changedValues).length === 0 &&
                        Object.keys(changedStatuses).length === 0) ||
                    submitting
                }
                type="submit"
                className="btn-primary col-span-4">
                Save
            </button>
            <button
                disabled={
                    (Object.keys(changedValues).length === 0 &&
                        Object.keys(changedStatuses).length === 0) ||
                    submitting
                }
                type="reset"
                className="btn-secondary col-span-4">
                Reset
            </button>
        </form>
    );
}

export default GeneralOrderInformation;
