import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../features/ui/uiSlice';
import { overlayVariants } from '../../../utils/constants';
import { useCheckoutContext } from '../../Checkout/CheckoutContext';
import { useInsuranceContext } from '../InsuranceContext';
import { useNavigate } from 'react-router';
import { toggleInsurance } from '../../../features/cart/cartSlice';

const Eligible = ({ onInsuranceStepsComplete }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cpapInCart, hstInCart, isConsultOrder, suppliesInCart } = useSelector(
        (state) => state.cart,
    );
    const { hstDepositPrice } = useSelector((state) => state.catalog);
    const { insurance } = useInsuranceContext();
    const { setCheckoutStep, skipInsurance } = useCheckoutContext();
    const getPromoText = () => {
        if (hstInCart) {
            return <b>Take $20 off your Home Sleep Study Purchase with code: STUDY20.</b>;
        } else if (cpapInCart) {
            return <b>Take $150 off your CPAP purchase with code: 150CPAP.</b>;
        } else if (!isConsultOrder) {
            // note: assuming if it is not sleep study, not cpap, and not consult, it is supplies
            return <b>Use Code SUPPLIES20 to take 20% off your supplies order.</b>;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (cpapInCart && insurance.payer?.dmeReferralOrganizationId !== null) {
        return (
            <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={overlayVariants}
                className="space-y-5">
                <h5 className="mb-4 font-heading text-4xl font-bold lg:text-5xl">
                    HERE ARE YOUR OPTIONS
                </h5>
                <p className="mb-4">
                    It looks like you entered {insurance.payer?.name} insurance. For CPAP, you have
                    two coverage options.
                </p>
                <ul>
                    <li className="mb-4">
                        1. To purchase your CPAP using your {insurance.payer?.name} insurance, GEM
                        SLEEP can send a referral to {insurance.payer?.name}'s CPAP provider.
                    </li>
                    <li className="mb-4">
                        2. You can place your order directly through GEM SLEEP without insurance.
                    </li>
                </ul>

                <button
                    className="btn-primary w-full"
                    onClick={() => {
                        skipInsurance({ showAlert: true });
                    }}>
                    Continue Without Insurance
                </button>

                <button
                    className="btn-shell w-full"
                    onClick={() => {
                        dispatch(toggleInsurance(false));
                        navigate('/dashboard/treatment/comparison');
                    }}>
                    <span>Learn More About Referral</span>
                </button>
            </motion.div>
        );
    } else if (
        (hstInCart && !insurance.payer.HSTCovered) ||
        (cpapInCart && !insurance.payer.CPAPCovered) ||
        (isConsultOrder && !insurance.payer.VirtualVisitCovered) ||
        (suppliesInCart && !insurance.payer.SuppliesCovered)
    ) {
        //show warning that payer doesn't cover sleep study
        return (
            <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={overlayVariants}
                className="space-y-5">
                <h5 className="mb-4 font-heading text-4xl font-bold lg:text-5xl">
                    HERE ARE YOUR OPTIONS TO PAY FOR YOUR ORDER
                </h5>

                <ul className="flex flex-col gap-4">
                    <li>
                        <b>Fastest option:</b> You can pay out of pocket with GEM SLEEP using
                        HSA/FSA dollars. {getPromoText()} Click “Continue without Insurance” if
                        you'd like to continue through GEM SLEEP without insurance.
                    </li>
                    <li>
                        <b>Use Different Insurance: </b> An item you are trying to purchase is not
                        covered by your current insurance. Select 'Use Other Insurance' if you have
                        a different plan you want to use.
                    </li>
                </ul>

                <button
                    className="btn-primary w-full"
                    onClick={() => {
                        skipInsurance({ showAlert: true });
                    }}>
                    Continue Without Insurance
                </button>

                <button
                    className="btn-shell w-full"
                    onClick={() => {
                        setCheckoutStep('insurance-details');
                    }}>
                    <span>Use other Insurance</span>
                </button>
            </motion.div>
        );
    } else {
        return (
            <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={overlayVariants}
                className="space-y-4 xs:space-y-5">
                <h5 className="mb-5 font-heading text-4xl font-bold lg:text-5xl">
                    We've got your insurance information!
                </h5>
                <p className="text-sm xs:text-base">
                    {hstInCart && insurance?.payer?.consultRequiredForHST
                        ? 'We have the health plan information you provided. Before sending you the Home Sleep Test kit, your health plan requires a virtual visit. You can schedule that next.'
                        : 'We have the health plan information you provided. We will submit a claim to your health plan directly, and follow up with you if we run into any issues or if you have a remaining amount due after your insurance is processed.'}
                </p>
                {hstInCart && insurance?.payer?.consultRequiredForHST && (
                    <>
                        <p className="text-sm xs:text-base">
                            We will submit claims to your health plan for the virtual visit and home
                            sleep study once completed. After your health plan processes the claims,
                            any remaining amount will be billed to you for payment.
                        </p>
                        <p className="font-bold">Virtual Visit Deposit*: ${hstDepositPrice}</p>
                        <p className="italic text-xs">
                            *Payment received after choosing visit time. ${hstDepositPrice} will
                            hold appointment time, and be applied to any future bill after insurance
                            processing.
                        </p>
                    </>
                )}
                <button
                    className="link"
                    onClick={() => {
                        dispatch(toggleModal('billedLater'));
                    }}>
                    How much will I owe?
                </button>
                <button
                    className="btn-primary w-full"
                    onClick={() => {
                        if (insurance) {
                            onInsuranceStepsComplete();
                        }
                    }}>
                    <span>Continue</span>
                </button>
                <hr className="hidden xl:block !mt-4 xs:!mt-12 border-b-2 border-gray-dark" />
                <h6 className="mb-4 text-center font-heading text-lg font-bold lg:text-xl">
                    or, you can always pay without insurance
                </h6>
                <button
                    className="btn-shell w-full"
                    onClick={() => {
                        skipInsurance({ showAlert: true });
                    }}>
                    continue without insurance
                </button>
            </motion.div>
        );
    }
};

export default Eligible;
