import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//Queries and Mutations
import { useGetPaginatedReferralsQuery } from '../../../../app/services/admin/referrals';
import { useCreateReferralMutation } from '../../../../app/services/orders';
import { useGetPartnerOrganizationsQuery } from '../../../../app/services/insurance';

//Components
import Modal from '../../../Modal/Modal';
import Pagination from '../../Pagination';
import 'react-datepicker/dist/react-datepicker.css';

import { referralStatuses } from '../../../../utils/constants';
import { ReferralsListItem } from '../../Referrals';

const columnLabels = ['Created', 'Updated', 'Sent', 'Status', 'Organization'];

const gridStyling =
    'grid grid-rows grid-cols-[100px_100px_130px_1fr_1fr] xl:grid-cols-[100px_100px_130px_1fr_1fr_160px_160px] gap-x-2 items-center';

const PatientReferrals = ({ patient }) => {
    //Local State
    const [page, setPage] = useState(0);
    const [addingReferral, setAddingReferral] = useState(false);
    const [values, setValues] = useState({
        userId: patient.id,
        referralStatus: 'REQUESTED',
        organizationId: null,
        referralType: 'DME',
    });

    //Queries and Mutations
    const { data: partners, isLoading: partnersLoading } = useGetPartnerOrganizationsQuery({
        paginated: false,
    });
    const { data: referrals, isFetching: referralsFetching } = useGetPaginatedReferralsQuery(
        {
            orderByKey: 'Created',
            orderByDesc: true,
            skip: page * 10,
            userId: patient.id,
        },
        { skip: !patient },
    );
    const [createReferral, { isLoading: isCreatingReferral }] = useCreateReferralMutation();

    //Calculated Values
    const inactiveReferrals = referrals?.results?.filter((referral) =>
        ['CANCELLED'].includes(referral.referralStatus),
    );

    const activeReferrals = referrals?.results?.filter((referral) =>
        ['REQUESTED', 'REFERRED'].includes(referral.referralStatus),
    );

    //Run a useEffect to set the organization ID to the first in list once partners have loaded
    useEffect(() => {
        if (partners && !values.organizationId) {
            setValues({ ...values, organizationId: partners.results[0].id });
        }
    }, [partners, values]);

    const dmeReferrers = partners?.results?.filter(
        (partner) => partner.organizationType === 'DME_PROVIDER',
    );

    return partnersLoading || referralsFetching ? (
        <p>Loading...</p>
    ) : (
        <>
            <div>
                <p className="text-gem-green">Active Referrals:</p>
                <div className={`${gridStyling} pt-4 max-w-[1400px]`}>
                    {columnLabels.map((label) => (
                        <p
                            key={`referrals-label-${label}`}
                            className="flex items-center text-sm gap-x-1 text-gray">
                            {label}
                        </p>
                    ))}
                </div>
                {activeReferrals.length > 0 ? (
                    activeReferrals.map((referral) => (
                        <ReferralsListItem
                            columns={columnLabels.length}
                            patientView={true}
                            gridStyling={gridStyling}
                            referral={referral}
                            partners={dmeReferrers}
                            key={referral.id + '-reoferrals-list-item'}
                        />
                    ))
                ) : (
                    <p className="font-heading tracking-widest text-gray my-2">
                        No active referrals
                    </p>
                )}
            </div>
            {activeReferrals.length === 0 && (
                <button
                    className="btn-primary-small mb-2"
                    onClick={() => {
                        setAddingReferral(true);
                    }}>
                    Add Referral
                </button>
            )}
            {inactiveReferrals.length > 0 && (
                <div>
                    <p className="text-red">Inactive Referrals:</p>

                    {inactiveReferrals.map((referral) => (
                        <ReferralsListItem
                            patientView={true}
                            gridStyling={gridStyling}
                            referral={referral}
                            partners={dmeReferrers}
                            key={referral.id + '-reoferrals-list-item'}
                        />
                    ))}
                </div>
            )}
            <Modal
                showClose={true}
                isOpen={addingReferral}
                closeHandler={() => setAddingReferral(false)}>
                <div className="space-y-2">
                    <p className="text-gray">Referral Status:</p>
                    <select
                        className="w-full rounded-md border text-black py-2"
                        multiple={false}
                        value={values?.referralStatus}
                        name="referralStatus"
                        onChange={(e) => setValues({ ...values, referralStatus: e.target.value })}>
                        {referralStatuses.map((status, idx) => (
                            <option key={`status-${idx}`} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                    <p className="text-gray">Organization:</p>
                    <select
                        className="w-full rounded-md border text-black py-2"
                        multiple={false}
                        value={values?.organizationId}
                        name="organizationId"
                        onChange={(e) => setValues({ ...values, organizationId: e.target.value })}>
                        <>
                            <option key="none">None</option>
                            {partners?.results.map((partner, idx) => (
                                <option key={`organization-${idx}`} value={partner.id}>
                                    {partner.name}
                                </option>
                            ))}
                        </>
                    </select>
                    <button
                        className="btn-primary-small"
                        disabled={isCreatingReferral}
                        onClick={async () => {
                            const referralAdded = await createReferral({
                                ...values,
                                organizationId:
                                    values.organizationId === 'None'
                                        ? null
                                        : parseInt(values.organizationId),
                            });
                            if (referralAdded) {
                                toast.success('Referral Added');
                                setAddingReferral(false);
                            } else {
                                toast.error('Error creating referral');
                            }
                        }}>
                        Add Referral
                    </button>
                </div>
            </Modal>
            {referrals?.metadata.total > 10 && (
                <Pagination
                    page={page}
                    disabled={referralsFetching}
                    setPage={setPage}
                    totalPages={Math.floor(referrals.metadata.total / 10)}
                />
            )}
        </>
    );
};

export default PatientReferrals;
