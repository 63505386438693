import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';
import { useAssignSleepStudyAsPrescriptionMutation, useGetMeQuery } from '../../app/services/auth';
import { UploadFile } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useGetUserTimelineQuery } from '../../app/services/auth';
import { toast } from 'react-toastify';

const SleepStudyUpload = () => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { refetch } = useGetMeQuery();
    const [assignSleepStudyAsPrescription] = useAssignSleepStudyAsPrescriptionMutation();

    const { refetch: refetchTimeline } = useGetUserTimelineQuery(user.id);

    //To be run after study upload
    const studySuccessCallback = async () => {
        refetchTimeline();
        //delay for user experience
        setTimeout(async () => {
            await refetch();
        }, 1000);
    };

    //To be run after prescription upload
    const prescriptionSuccessCallback = async () => {
        await refetch();
        //delay for user experience
        setTimeout(() => {
            navigate('../study-in-review', { replace: true });
        }, 1000);
    };

    const studyUploaded = user?.sleepStudyFileId;

    return (
        <div className="layout-outlet-root max-w-[1400px]">
            <div className="p-5 lg:p-0 max-w-5xl lg:mb-12 space-y-4">
                <h3 className="text-[44px] lg:text-6xl font-heading mb-4 leading-10">
                    Upload your <span>{studyUploaded ? 'prescription' : 'sleep report'}</span>
                </h3>
                {studyUploaded ? (
                    <p className="text-sm lg:text-lg leading-normal mb-6">
                        We've got your sleep study.{' '}
                        <span className="font-bold">Now upload your prescription.</span> After
                        uploading your prescription we will review your documents and contact you
                        when your results are ready.
                    </p>
                ) : (
                    <p className="text-sm lg:text-lg leading-normal mb-6">
                        No need to get a new test! Just upload the{' '}
                        <span className="font-bold">sleep study report</span> and{' '}
                        <span className="font-bold">prescription</span> from your prior study. A GEM
                        SLEEP provider will review and give you treatment options.
                    </p>
                )}

                <div className="space-y-4 lg:text-xl text-center">
                    {studyUploaded ? (
                        <>
                            <h3 className="text-2xl lg:text-4xl font-heading leading-10">
                                Upload your prescription
                            </h3>
                            <UploadFile
                                key="study-upload"
                                isModal={false}
                                fileType={'PRESCRIPTION'}
                                uploadSuccessCallback={prescriptionSuccessCallback}
                            />

                            <button
                                className="font-heading text-green-400 tracking-wider hover:text-green-hover"
                                onClick={() => {
                                    dispatch(toggleModal('problemsUploading'));
                                }}>
                                Having problems uploading?
                            </button>
                            <p className="font-heading tracking-wider">
                                If your sleep study also contained your prescription
                            </p>
                            <button
                                className="btn-shell-small w-[250px]"
                                onClick={async () => {
                                    const response = await assignSleepStudyAsPrescription({
                                        id: user?.id,
                                    });
                                    if (response?.data) {
                                        navigate('../study-in-review', { replace: true });
                                    } else {
                                        toast.error('Something went wrong, please try again.');
                                    }
                                }}>
                                Click Here
                            </button>
                        </>
                    ) : (
                        <>
                            <h3 className="text-2xl lg:text-4xl font-heading mb-4 leading-10">
                                Upload your sleep study
                            </h3>
                            <UploadFile
                                key="prescription-upload"
                                isModal={false}
                                fileType={'UPLOADED_SLEEP_STUDY'}
                                uploadSuccessCallback={studySuccessCallback}
                            />
                            <button
                                className="font-heading text-green-400 tracking-wider hover:text-green-hover"
                                onClick={() => {
                                    dispatch(toggleModal('problemsUploading'));
                                }}>
                                Having problems uploading?
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SleepStudyUpload;
