import { useSelector } from 'react-redux';
import { ReactComponent as Download } from '../../images/sleep-study-download.svg';

function SleepStudyDownloadLink({ styling }) {
    const { storageToken, user } = useSelector((state) => state.auth);

    if (user?.prescriptionFileId && storageToken)
        return (
            <a
                href={`${process.env.REACT_APP_API_BASE_URL}/files/prescription/${user?.prescriptionFileId}?storageToken=${storageToken}`}
                target="_blank"
                rel="noreferrer"
                className={`${styling}`}>
                Download sleep report
                <Download className="absolute right-3 top-1/2 -translate-y-1/2 lg:right-5" />
            </a>
        );
    else return null;
}

export default SleepStudyDownloadLink;
